import {Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './dashboard/Login';
import Dashboard from './dashboard/app/';
import { ProtectedRoute } from './dashboard/ProtectedRoute';
import { useAuth } from './hooks/useAuth';
import Down from './dashboard/pages/Down';

function App() {
  const {isAuthenticated} = useAuth()
  return (
    <Routes>
      <Route exact path="/" element={<Down/>} />
      <Route exact path="login" element={<Down/>} />
      <Route exact path="users" element={ isAuthenticated ? <Dashboard/> : <Navigate to="/login" />} />
    </Routes>
  );
}

export default App;
