import {useState, useEffect} from 'react'
import { getAPIBaseURL, getSettings, getSiteBaseURL, getUser } from "../../services/helpers";
import { deleteData, getData } from "../../services/request";
import { validateTitle, validateText } from "../../services/validators";
import Popup from '../../components/Popup';
import EditPopup from '../../components/EditPopup';
//import picture from 'https://twitter.com/yytambaya_/photo'

const Lits = ({setPage, setLastPage, setLit, setThisUser}) => {
    const [user, setUser] = useState({});
    const [profilePic, setProfilePic] = useState("")
    const [settings, setSettings] = useState({})
    const [lits, setLits] = useState([]);
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [publish, setPublish] = useState("0")
    const [author, setAuthor] = useState("")
    const [limit, setLimit] = useState("10")
    const [skip, setSkip] = useState("0")
    const [bottomLoading, setBottomLoading] = useState(false)
    const [notification, setNotification] = useState(false)
    const [deleteId, setDeleteId] = useState(false)
    const [pageEnd, setPageEnd] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false)
    const [error, setError] = useState([{field: "title", msg:""}, {field: "text", msg:""}]);
    const [genError, setGenError] = useState("")
    const [updatePopuError, setUpdatePopupError] = useState("")
    const [deletePopupError, setDeletePopupError] = useState("")

    useEffect( () => {
        const user = getUser()
        const settings = getSettings()
        if(!user){
          window.location.href = `${getSiteBaseURL()}/login`
        }else{
            setUser(user)
        }  
        if(settings){
            setSettings(settings)
        }
        alert(JSON.stringify(settings))
    }, [])

    useEffect( () => {
        if(notification){
            setTimeout(() => {
                setNotification(false)
            }, 3600)
        }
    }, [notification])

    useEffect( () => {
        getLits()
    }, [skip])

    window.onscroll = (e) => handleScroll(e);

    const handleScroll = (e) => {
      
      if(window.innerHeight + e.target.documentElement.scrollTop + 1 >= e.target.documentElement.scrollHeight){
        //alert("Reached end")
        
        setSkip(lits?.length)
        //console.log("At the bottom")
        //fetchMoreData(); 
        //alert("Fetch next 10")
      }
    }

    const DeletePopup = () => {
        <Popup/>
    }
  
    /*const EditPopup = () => {
        <Popup/>
    }*/

    const getLits = async () => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        //alert('getting lits..')
        var accessToken = localStorage.getItem('token');
        const user = getUser()
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
        
        if(user && accessToken.length){
            //alert("going")
            const url = `${getAPIBaseURL()}/v1/students/center`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            const headers = {'token': accessToken}
            const params = {centerId: user._id, limit:limit , skip:skip};

            const requestResult = await getData(url, headers, params)
            
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    //alert("Successful: 200")
                    //setNotification(true)
                    //alert(JSON.stringify(requestResult.result.data.data))
                    //setPage('Lit')
                    setLits([...lits, ...requestResult.result.data.data])
                    
                }else{
                    setGenError('something went wrong')
                }

            }else{
                alert('error')
                setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
            }
            
            
            /*if(request.error == ""){
                if(request.result.data.error == ""){
                    //alert(JSON.stringify(request.result.data.result))
                    if(request.result.data.result.length == 0){
                        setPageEnd(true)
                        setBottomLoading(false)
                    }else{
                        setLits([...lits, ...request.result.data.result])
                    }
                    //window.location.href = `${getAPIBaseURL()}/app`

                }else{
                    //alert("Eror")
                    setGenError(request.result.data.result)
                }

            }else{
                setGenError("Something went wrong")
            }*/
            
        }  
    }

    const deleteStudent = async (studentId) => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        //alert('Delete Student')
        var accessToken = localStorage.getItem('token');
        const user = getUser()
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
        
        if(user && accessToken.length){
            alert("going")
            const url = `${getAPIBaseURL()}/v1/students`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            const headers = {'token': accessToken}
            const params = {studentId: studentId};

            const requestResult = await deleteData(url, headers, params)
            
            if(requestResult.error == ""){
                alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    alert("Successful: 200")
                    //setNotification(true)
                    //alert(JSON.stringify(requestResult.result.data.data))
                    //setPage('Lit')
                    //setLits([...lits, ...requestResult.result.data.data])
                    setDeletePopup(false)
                    setNotification(true)
                    
                }else{
                    setGenError('something went wrong')
                }

            }else{
                alert('error')
                setDeletePopupError(requestResult.result.response.data?.message ? requestResult.result.response.data?.message : requestResult.result.response.data?.data)
                setNotification(true)    
                //setGenError(requestResult.result.response.data?.message ? requestResult.result.response.data?.message : requestResult.result.response.data?.data)
            }
            
            
            /*if(request.error == ""){
                if(request.result.data.error == ""){
                    //alert(JSON.stringify(request.result.data.result))
                    if(request.result.data.result.length == 0){
                        setPageEnd(true)
                        setBottomLoading(false)
                    }else{
                        setLits([...lits, ...request.result.data.result])
                    }
                    //window.location.href = `${getAPIBaseURL()}/app`

                }else{
                    //alert("Eror")
                    setGenError(request.result.data.result)
                }

            }else{
                setGenError("Something went wrong")
            }*/
            
        }  
    }

    

    const navigateToEditPage = (lit) => {
        setLit(lit)
        setPage("EditLit")
        setLastPage("Lits")
    }
    const navigateToViewPage = (lit) => {
        setLit(lit)
        setPage("SingleLit")
        setLastPage("Lits")
    }
    

    return(
        <div>
             {notification && <div id="toast-success" class="sticky top-0 flex items-center w-full p-2 mb-4 text-white bg-green-700 shadow" role="alert">
                
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Check icon</span>
                </div>
                <div class="ml-3 text-md font-normal">Student successfully deleted</div>
                <button type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 " data-dismiss-target="#toast-success" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div> }
        <section class="md:mx-12 text-gray-600 body-font">
        <div class="container px-5 py-10 mx-auto">
            
          <div class="flex flex-wrap w-full mb-10 justify-between text-center">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Students</h1>
            {settings?.registration ? 
                <button onClick={() => setPage("NewLit")} type="button" class="text-white bg-black hover:bg-black/90 focus:ring-4 focus:outline-none focus:ring-black/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-black/55 mr-2 mb-2">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    New Student
                </button> 
                :
                <button disabled type="button" class="text-white bg-gray-400 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    New Student
                </button>
            }
          </div>
          {genError && <div><p className='text-red-500'>{genError}</p> </div>}
          {deletePopup && <Popup name={'Abba sani'} deleteStudent={deleteStudent} studentId={deleteId} setDeletePopup={setDeletePopup} deletePopupError={deletePopupError} />}
          {editPopup && <EditPopup/>}
          
          <div class="flex flex-wrap -m-4">



                <div class="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
                    <div class="flex items-center justify-between py-4 bg-white mx-4">
                        <div>
                            {/*<button id="dropdownActionButton" data-dropdown-toggle="dropdownAction" class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 " type="button">
                                <span class="sr-only">Action button</span>
                                Action
                                <svg class="w-3 h-3 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                            </button>*/}
                                <button onClick={ () => window.print()} type="submit" class="text-white bg-gray-300 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">print</button>
                            
                            <div id="dropdownAction" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ">
                                <ul class="py-1 text-sm text-gray-700 " aria-labelledby="dropdownActionButton">
                                    <li>
                                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">Reward</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">Promote</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 ">Activate account</a>
                                    </li>
                                </ul>
                                <div class="py-1">
                                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Delete User</a>
                                </div>
                            </div>
                        </div>
                        <label for="table-search" class="sr-only">Search</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input type="text" id="table-search-users" class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Search for users"/>
                        </div>
                    </div>
                    <table class="w-full text-sm text-left text-gray-500 ">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                {/*<th scope="col" class="p-4">
                                    <div class="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                    </div>
                                </th>*/}
                                <th scope="col" class="py-3">
                                    Name
                                </th>
                                <th scope="col" class="py-3">
                                    Student Number
                                </th>
                                <th scope="col" class="py-3">
                                    Exam Year
                                </th>
                                <th scope="col" class="py-3">
                                    Status
                                </th>
                                <th scope="col" class="py-3">
                                    Edit
                                </th>
                                <th scope="col" class="py-3">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lits.map((student, i) => 
                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                
                                    <th onClick={() => navigateToViewPage(student)} scope="row" class="flex items-center cursor-pointer px-6 py-4 text-gray-900 whitespace-nowrap ">
                                        <img class="w-10 h-10 rounded-full" src={`${getAPIBaseURL()}/uploads/students/passports/${student?.number}.png`} alt="Jese image"/>
                                        <div class="pl-3">
                                            <div class="text-base font-semibold">{`${student.name.firstName} ${student.name.middleName} ${student.name.surname}`}</div>
                                            <div class="font-normal text-gray-500">{student.email}</div>
                                        </div>  
                                    </th>
                                    <td class="py-4">
                                        { `${student.number}`}
                                    </td>
                                    <td class="py-4">
                                        <div class="flex items-center">
                                           </div> {student.examYear}
                                    </td>
                                    <td class="py-4">
                                        {student.status ? 
                                        <svg class="w-5 h-5 text-green-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                        :
                                        <svg class="w-5 h-5 text-red-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        } 
                                    </td>
                                    <td class="py-4">
                                        {settings?.modification ? <button onClick={() => navigateToEditPage(student)} href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-blue-600  hover:underline">Edit</button> : <a href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-gray-400  hover:underline">Edit</a>}
                                    </td>
                                    <td class="py-4">
                                        {settings?.deletion ? <button onClick={() => { setDeletePopup(true); setDeleteId(student._id) }} href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-blue-600  hover:underline">Delete</button> : <a href="#" type="button" data-modal-target="editUserModal" data-modal-show="editUserModal" class="font-medium text-gray-400  hover:underline">Delete</a>}
                                    </td>
                                </tr>
                            )}
                            
                        </tbody>
                    </table>
                    

                    <div id="editUserModal" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
                        <div class="relative w-full max-w-2xl max-h-full">
                          
                            <form action="#" class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                
                                <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                        Edit user
                                    </h3>
                                    <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="editUserModal">
                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                                    </button>
                                </div>
                                
                                <div class="p-6 space-y-6">
                                    <div class="grid grid-cols-6 gap-6">
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="first-name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                                            <input type="text" name="first-name" id="first-name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Bonnie" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="last-name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                                            <input type="text" name="last-name" id="last-name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Green" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                            <input type="email" name="email" id="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="example@company.com" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="phone-number" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                                            <input type="number" name="phone-number" id="phone-number" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="e.g. +(12)3456 789" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="department" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Department</label>
                                            <input type="text" name="department" id="department" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Development" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="company" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company</label>
                                            <input type="number" name="company" id="company" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123456" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="current-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Password</label>
                                            <input type="password" name="current-password" id="current-password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="••••••••" required=""/>
                                        </div>
                                        <div class="col-span-6 sm:col-span-3">
                                            <label for="new-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                            <input type="password" name="new-password" id="new-password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="••••••••" required=""/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save all</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


            {/*lits.map((lit, i) => 
                <div onClick={() => changePage(lit)} class="xl:w-1/3 md:w-1/2 p-4 cursor-pointer">
                <div class="border border-gray-200 p-6 rounded-lg">
                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                    <img class="w-10 h-10 rounded-full" src='https://pbs.twimg.com/profile_images/1486965541948600322/P3ecN5nG_400x400.jpg' /> 
                    </div>
                    <h2 class="text-lg text-gray-900 font-medium title-font mb-2 truncate">{lit.firstName}</h2>
                    <p class="leading-relaxed text-base block line-clamp-2">{`${lit.name.firstName} ${lit.name.middleName} ${lit.name.surname}`}</p>
                    <div class="flex justify-between mt-4">
                        <p class="flex self-end">{lit.status == "0"? "inactive": "active"}</p>
                        <p>{`${lit.number}`}</p>
                    </div>
                </div>
                </div>
            )*/}
            
          </div>
        </div>
        {bottomLoading && <div className='mx-auto py-6'><p>Loading...</p></div>}
      </section>
      </div>
    )
}



export default Lits;