import {useState, useEffect} from 'react'
import { getAPIBaseURL, getSiteBaseURL, getUser } from "../../services/helpers";
import { getData, postData, updateData } from "../../services/request";
import { validateTitle, validateText, validateName, validateEmail, validatePhoneNumber, validateGender, validateDate, validateNIN, validateLink, validateState, validateLocalGovernment, validateAddress, validateNumber, validateStatus,validateOLevelCode, validateALevelCode, validateUsername, validateInstitution, validateImage, validateCombination, validateMiddleName } from "../../services/validators";
import { aLevelCourses, localGovernments, oLevelCourses } from '../../data';
import { WebcamCapture } from '../../components/CustomWebCam';

const EditLit = ({setPage, setLastPage, lastPage, lit}) => {
    const [user, setUser] = useState({})
    //const [title, setTitle] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [surname, setSurname] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState()
    const [dob, setDOB] = useState("")
    const [nin, setNIN] = useState("")
    const [photo, setPhoto] = useState("")
    const [state, setState] = useState()
    const [localGovernment, setLocalGovernment] = useState()
    //const [district, setDistrict] = useState("")
    //const [houseAddress, setHouseAddress] = useState("")
    //const [examYear, setExamYear] = useState("")
    const [centerId, setCenterId] = useState("")
    const [institutionNumber, setInstitutionNumber] = useState("")
    const [oLevelCode, setOLevelCode] = useState('')
    const [aLevelCode, setALevelCode] = useState('')
    const [role, setRole] = useState("")
    const [status, setStatus] = useState(0)
    const [nigerianStates, setNigerianStates] = useState([]) 
    const [stateLocalGovernments, setStateLocalGovernments] = useState([]) 
    const [oLevelSubjects, setOLevelSubjects] = useState([]) 
    const [aLevelSubjects, setALevelSubjects] = useState([])
    const [uploadCapture, setUploadCapture] = useState(false)
    const [liveCapture, setLiveCapture] = useState(false)
    const [photoURL, setPhotoURL] = useState(null)
    
    
    const [text, setText] = useState("")
    const [publish, setPublish] = useState("0")
    const [author, setAuthor] = useState("")
    const [action, setAction] = useState("save")
    const [notification, setNotification] = useState(false)
    const [error, setError] = useState([{field: "firstName", msg:""}, {field: "middleName", msg:""}, {field: "surname", msg:""},
    {field: "phoneNumber", msg:""}, {field: "email", msg:""}, {field: "gender", msg:""}, {field: "dob", msg:""}, {field: "nin", msg:""}, {field: "photo", msg:""},
    {field: "state", msg:""}, {field: "localGovernment", msg:""}, {field: "institutionNumber", msg:""},
    {field: "oLevelCode", msg:""}, {field: "aLevelCode", msg:""}, {field: "status", msg:""}]);
    const [genError, setGenError] = useState("")

    useEffect( () => {
        const user = getUser()
        if(!user){
          window.location.href = `${getSiteBaseURL()}/login`
        }else{
            setUser(user)
        }  
      /*if(localStorage.getItem('jwt_token') == "" || localStorage.getItem('jwt_token') == null || localStorage.getItem('jwt_token') == undefined){
            window.location.href = `${getSiteBaseURL()}/login`
        }*/
    }, [])

    useEffect(() => {
        alert(JSON.stringify(lit))
        //alert('Status: ' + typeof(lit.status))
        if(lit){
            setFirstName(lit.name.firstName)
            setMiddleName(lit.name.middleName)
            setSurname(lit.name.surname)
            setPhoneNumber(lit.phoneNumber)
            setEmail(lit.email)
            setGender(lit.gender)
            setDOB(lit.dob.substring(0, 10))
            setNIN(lit.nin)
            //setPhoto(lit.photo)
            setState(lit.address.state)
            setLocalGovernment(lit.address.localGovernment)
            lit.institutionNumber == null ? setInstitutionNumber('') : setInstitutionNumber(lit.institutionNumber)
            lit.oLevelCode == null ? setOLevelCode('') : setOLevelCode(lit.oLevelCode)
            lit.aLevelCode == null ? setALevelCode('') : setALevelCode(lit.aLevelCode)
            setStatus(lit.status)
        }
    }, [])

    useEffect(() => {
        if(user){
            loadStates()
            loadOLevelSubjects()
            loadALevelSubjects()
        }
    }, [])

    useEffect(() => {
        if(user && state){
            loadLocalGovernments(state)
        }
    }, [state])

    useEffect( () => {
        if(notification){
            setTimeout(() => {
                setNotification(false)
                setPage('Lits')
            }, 3600)
        }
    }, [notification])

    
    const updateLit = async (user, studentId) => {
        alert("StudentId: " + studentId);
        var accessToken = localStorage.getItem('token');
        var centerId = user._id
        
        var firstNameVal = validateName(firstName).error == "" ? true: false;
        var middleNameVal = validateMiddleName(middleName).error == "" ? true: false;
        var surnameVal = validateName(surname).error == "" ? true: false;
        var phoneNumberVal = validatePhoneNumber(phoneNumber).error == "" ? true: false;
        var emailVal = validateEmail(email).error == "" ? true: false;
        var genderVal = validateGender(gender).error == "" ? true: false;
        var dobVal = validateDate(dob).error == "" ? true: false;
        var ninVal = validateNIN(nin).error == "" ? true: false;
        //var photoVal = validateImage(photo).error == "" ? true: false;
        var stateVal = validateState(state).error == "" ? true: false;
        var localGovernmentVal = validateLocalGovernment(localGovernment).error == "" ? true: false;
        //var districtVal = validateAddress(district).error == "" ? true: false;
        //var houseAddressVal = validateAddress(houseAddress).error == "" ? true: false;
        //var examYearVal = validateDate(examYear).error == "" ? true: false;
        var institutionNumberVal = validateInstitution(institutionNumber).error == "" ? true: false;
        var oLevelCodesVal =validateOLevelCode(oLevelCode).error == "" ? true: false;
        var aLevelCodeVal = validateALevelCode(aLevelCode).error == "" ? true: false;
        var statusVal = validateStatus(status).error == "" ? true: false;
        var combinationVal = validateCombination(oLevelCode, aLevelCode).error == "" ? true : false;
        

        //var tex_val = validateText(text).error == "" ? true: false;
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        //var ui_val = user_id == "" || user_id == undefined? false : true; 
        //var un_val = user_name == "" || user_name == undefined? false : true; 
        
        //if(accessToken == "") setGenError("Unauthorized user. Login again!"); 
        //if(user_id == "") setGenError("Unauthorized user, no id. Login again!"); 
        //if(user_name == "")  setGenError("Unauthorized user, no name. Login again!"); 
        //setError([...error, error.find(item => item.field == "title").msg = validateName(title).result])
        setError([...error, error.find(item => item.field == "firstName").msg = validateName(firstName).result])
        setError([...error, error.find(item => item.field == "middleName").msg = validateMiddleName(middleName).result])
        setError([...error, error.find(item => item.field == "surname").msg = validateName(surname).result])
        setError([...error, error.find(item => item.field == "phoneNumber").msg = validatePhoneNumber(phoneNumber).result])
        setError([...error, error.find(item => item.field == "email").msg = validateEmail(email).result])
        setError([...error, error.find(item => item.field == "gender").msg = validateGender(gender).result])
        setError([...error, error.find(item => item.field == "dob").msg = validateDate(dob).result])
        setError([...error, error.find(item => item.field == "nin").msg = validateNIN(nin).result])
        //setError([...error, error.find(item => item.field == "photo").msg = validateImage(photo).result])
        setError([...error, error.find(item => item.field == "state").msg = validateState(state).result])
        setError([...error, error.find(item => item.field == "localGovernment").msg = validateLocalGovernment(localGovernment).result])
        //setError([...error, error.find(item => item.field == "district").msg = validateAddress(district).result])
        //setError([...error, error.find(item => item.field == "houseAddress").msg = validateAddress(houseAddress).result])
        //setError([...error, error.find(item => item.field == "examYear").msg = validateDate(examYear).result])
        setError([...error, error.find(item => item.field == "institutionNumber").msg = validateInstitution(institutionNumber).result])
        setError([...error, error.find(item => item.field == "oLevelCode").msg =validateOLevelCode(oLevelCode).result])
        setError([...error, error.find(item => item.field == "aLevelCode").msg = validateALevelCode(aLevelCode).result])
        setError([...error, error.find(item => item.field == "status").msg = validateStatus(status).result])
        setError([...error, error.find(item => item.field == "aLevelCode").msg = validateCombination(oLevelCode, aLevelCode).result])
        
        if(firstNameVal && surnameVal && phoneNumberVal && emailVal && genderVal && dobVal && ninVal && stateVal && localGovernmentVal  && statusVal && combinationVal){
           //tackle middleName, aLevel, oLevel, institutionNum 
        //let pass = true;        
        /*for(let a = 0; a < error.length-1; a++){
            if(error[a].msg != ""){
                alert('Loop: ' + error[a].field + " " + error[a]?.msg)
                pass = false;
                break;
            }
            //alert('Field: ' + error[a].field)
        }*/

        //if(pass){
            
            alert("Everythin is intact")
            alert(`FN: ${firstName} MN: ${middleName} LN: ${surname} PN: ${phoneNumber} Email: ${email} Gender: ${gender}
            DOB: ${dob} NIN: ${nin} photo: ${photo} state: ${state} local Gov: ${localGovernment} Institute No: ${institutionNumber} aLevel codes: ${aLevelCode}
            status: ${status} O Level codes: ${oLevelCode} centerId: ${centerId} token: ${accessToken}`)

            setAction("Loading...")
            const url = `${getAPIBaseURL()}/v1/students`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            const headers = { 'Content-Type' : 'multipart/form-data', 'token': accessToken}
            const data = {firstName, middleName, surname, phoneNumber, email, gender, dob, nin, photo, state, localGovernment, institutionNumber, oLevelCode, aLevelCode, status, role: 'STUDENT', centerId};
            const formData = new FormData()
            formData.append('firstName', firstName)
            formData.append('middleName', middleName)
            formData.append('surname', surname)
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)
            formData.append('gender', gender)
            formData.append('dob', dob)
            formData.append('nin', nin)
            formData.append('state', state)
            formData.append('localGovernment', localGovernment)
            formData.append('institutionNumber', institutionNumber)
            formData.append('oLevelCode', oLevelCode)
            formData.append('aLevelCode', aLevelCode)
            alert('Center Number: ' + user?.number)
            formData.append('centerNumber', user.number)
            formData.append('centerId', centerId)
            formData.append('studentId', studentId)
            
            // formData.append('photo', photo)
            alert("a Level: " + aLevelCode + " o Level: " + oLevelCode)
            
            const requestResult = await updateData(url, headers, formData)
            setAction('save')
            if(requestResult.error == ""){
                alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    alert("Student Successfully updated")
                    setNotification(true)
                    //alert(JSON.stringify(requestResult.result.data.data))
                    //setPage('Lit')
                }else{
                    setGenError('something went wrong')
                }

            }else{
                alert(requestResult.result.response.data.message ? requestResult.result.response.data.message : requestResult.result.response.data.data)
                setGenError(requestResult.result.response.data.message ? requestResult.result.response.data.message : requestResult.result.response.data.data)
            }
        } 
    }

    const loadStates = async () => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        //alert('getting lits..')
        var accessToken = localStorage.getItem('token');
        const user = getUser()
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
        
        if(user && accessToken.length){
            alert("going")
            const url = `${getAPIBaseURL()}/v1/states`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            const headers = {'token': accessToken}
            // const params = {centerId: user._id, limit:limit , skip:skip};
            const params = {}

            const requestResult = await getData(url, headers, params)
            
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    setNigerianStates([...nigerianStates, ...requestResult.result.data.data])
                    
                }else{
                    setGenError('something went wrong')
                }

            }else{
                alert('error from server')
                console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
            }
            
        }  
    }

    const loadLocalGovernments = async (stateCode) => {
        //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
        //alert('getting lits..')
        var accessToken = localStorage.getItem('token');
        const user = getUser()
        //var at_val = accessToken == "" || accessToken == undefined? false : true; 
        if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
        
        if(user && accessToken.length){
            //alert("going")
            const url = `${getAPIBaseURL()}/v1/localgovernments`;
            const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
            const headers = {'token': accessToken}
            // const params = {centerId: user._id, limit:limit , skip:skip};
            const params = {stateCode: stateCode}

            const requestResult = await getData(url, headers, params)
            
            if(requestResult.error == ""){
                //alert('No error')
                //alert(requestResult.result.status)
                if(requestResult.result.status === 200){
                    setStateLocalGovernments([...stateLocalGovernments, ...requestResult.result.data.data])
                    
                }else{
                    setGenError('something went wrong')
                }

            }else{
                alert('error from server')
                console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
            }
            
        }
    }   

        const loadOLevelSubjects = async () => {
            //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
            //alert('getting lits..')
            var accessToken = localStorage.getItem('token');
            const user = getUser()
            //var at_val = accessToken == "" || accessToken == undefined? false : true; 
            if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
            
            if(user && accessToken.length){
                //alert("going")
                const url = `${getAPIBaseURL()}/v1/combinations/olevel`;
                const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
                const headers = {'token': accessToken}
                // const params = {centerId: user._id, limit:limit , skip:skip};
                const params = {}
    
                const requestResult = await getData(url, headers, params)
                
                if(requestResult.error == ""){
                    //alert('No error')
                    //alert(requestResult.result.status)
                    if(requestResult.result.status === 200){
                        setOLevelSubjects([...oLevelSubjects, ...requestResult.result.data.data])
                        
                    }else{
                        setGenError('something went wrong')
                    }
    
                }else{
                    alert('error from server')
                    console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                    setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                }
                
            }
        }    

            const loadALevelSubjects = async () => {
                //alert("Title: " + title + " Text: " + text + " Checked: " + publish);
                //alert('getting lits..')
                var accessToken = localStorage.getItem('token');
                const user = getUser()
                //var at_val = accessToken == "" || accessToken == undefined? false : true; 
                if(accessToken == "" || user.length == 0) setGenError("Unauthorized user. Login again!"); 
                
                if(user && accessToken.length){
                    //alert("going")
                    const url = `${getAPIBaseURL()}/v1/combinations/alevel`;
                    const api_key = '@!8(T#7<R:I#:F1#r!>BW/!';
                    const headers = {'token': accessToken, 'Content-Type' : ''}
                    // const params = {centerId: user._id, limit:limit , skip:skip};
                    const params = {}
        
                    const requestResult = await getData(url, headers, params)
                    
                    if(requestResult.error == ""){
                        //alert('No error')
                        //alert(requestResult.result.status)
                        if(requestResult.result.status === 200){
                            setALevelSubjects([...aLevelSubjects, ...requestResult.result.data.data])
                            
                        }else{
                            setGenError('something went wrong')
                        }
        
                    }else{
                        alert('error from server')
                        console.log(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                        setGenError(requestResult.result.data?.message ? requestResult.result.data?.message : requestResult.result.data?.data)
                    }
                    
                } 

            }   
    
    const handlePhotoUpload = (e) => {
        setPhoto(e.target.files[0])
        setPhotoURL(URL.createObjectURL(e.target.files[0]))
        setUploadCapture(true)
        
        /*const file = e.target.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            setPhotoURL(fileReader.result);
        });
        fileReader.readAsDataURL(file);*/
    }        

    const setNull = () => {
        return null
    }

    return(
        
        <div>
           {notification && <div id="toast-success" class="sticky top-0 flex items-center w-full p-2 mb-4 text-white bg-green-700 shadow" role="alert">
                
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Check icon</span>
                </div>
                <div class="ml-3 text-md font-normal">New student successfully registered</div>
                <button type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 " data-dismiss-target="#toast-success" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
            </div> }

        <div class="mx-5 md:mx-20 mt-10 mb-10">
            <div class="flex items-center mb-10">
                <div>
                    <button onClick={() => {setPage(lastPage);}} type="button" class="text-white bg-gray-700 hover:bg-black focus:ring-4 focus:outline-none focus:ring-black font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                <div className='ml-2 mb-2'>
                    <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">Update Student Details</h1>
                </div>
            </div>
            <div class="my-5">
                {genError != "" ? <div class="text-red-500 text-sm font-semibold">{genError}</div> : ""}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Surname</label>
                <input onChange={(e) => setSurname(e.target.value)} value={surname} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Abba" required=""/>
                {error.find(item => item.field == "surname").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "surname").msg }</p>: null}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">First Name</label>
                <input onChange={(e) => setFirstName(e.target.value)} value={firstName} type="title" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="John?" required=""/>
                {error.find(item => item.field == "firstName").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "firstName").msg }</p>: null}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Middle Name</label>
                <input onChange={(e) => setMiddleName(e.target.value)} value={middleName} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Samuel" required=""/>
                {error.find(item => item.field == "middleName").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "middleName").msg }</p>: null}
            </div>

            <div className="sm:col-span-3 mb-6">
            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Gender</label>
              <div className="mt-2">
                <select onChange={(e) => setGender(e.target.value)}
                  value= {gender}  
                  id="gender"
                  name="gender"
                  autoComplete="country-name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">--select--</option>  
                  <option value={1}>Male</option>
                  <option value={2}>Female</option>
                </select>
                {error.find(item => item.field == "gender").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "gender").msg }</p>: null}
              </div>
            </div>

            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Date of Birth</label>
                <input onChange={(e) => setDOB(e.target.value)} value={dob} type="date" datatype='' id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="1997-02-05" required=""/>
                {error.find(item => item.field == "dob").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "dob").msg }</p>: null}
            </div>
         
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Phone Number</label>
                <input onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="09000000000" required=""/>
                {error.find(item => item.field == "phoneNumber").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "phoneNumber").msg }</p>: null}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Email Address</label>
                <input onChange={(e) => setEmail(e.target.value)} value={email} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="johnsamuel123@gmail.com" required=""/>
                {error.find(item => item.field == "email").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "email").msg }</p>: null}
            </div>
            <div class="mb-6">

                {/*
                <label for="titile" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gender</label>
                <input onChange={(e) => setGender(e.target.value)} type="titile" id="titile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Male" required=""/>
                {error.find(item => item.field == "gender").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "gender").msg }</p>: null}
                */}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">NIN Number</label>
                <input onChange={(e) => setNIN(e.target.value)} value={nin} type="number" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="92615378913" required=""/>
                {error.find(item => item.field == "nin").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "nin").msg }</p>: null}
            </div>
            {/*<div class="mb-6">
                <label for="titile" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Gender</label>
                <input onChange={(e) => setGender(e.target.value)} type="titile" id="titile" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="What is bitcoin?" required=""/>
                {error.find(item => item.field == "gender").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "gender").msg }</p>: null}
            </div>*/}

            <div class="mb-6">   
            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                State
              </label>
              <div className="mt-2">
                <select onChange={(e) => setState(e.target.value)}
                  value={state}
                  id="state"
                  name="state"
                  autoComplete="country-name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">--select--</option>  
                  {nigerianStates.map((theState, i) => 
                     <option value={theState.code}>{theState.name}</option>
                  )}
                </select>
                {error.find(item => item.field == "state").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "state").msg }</p>: null}
              </div>
            </div>
            </div>

            <div class="mb-6">   
            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                Local Government
              </label>
              <div className="mt-2">
                <select onChange={(e) => setLocalGovernment(e.target.value)}
                  value={localGovernment}  
                  id="state"
                  name="state"
                  autoComplete="country-name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">--select--</option>  
                  {stateLocalGovernments.map((theLoc, i) => 
                      <option value={theLoc.code}>{theLoc.name}</option> 
                  )}
                </select>
                {error.find(item => item.field == "localGovernment").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "localGovernment").msg }</p>: null}
              </div>
            </div>
            </div>
           {/*
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">District</label>
                <input onChange={(e) => setDistrict(e.target.value)} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Kwarbai" required=""/>
                {error.find(item => item.field == "district").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "district").msg }</p>: null}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">House Address</label>
                <input onChange={(e) => setHouseAddress(e.target.value)} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="No 7 Katuka Street" required=""/>
                {error.find(item => item.field == "houseAddress").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "houseAddress").msg }</p>: null}
            </div>
            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Examination Year</label>
                <input onChange={(e) => setExamYear(e.target.value)} type="date" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2022" required=""/>
                {error.find(item => item.field == "examYear").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "examYear").msg }</p>: null}
            </div>
            */}

            <div class="mb-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">Institution Number</label>
                <input onChange={(e) => setInstitutionNumber(e.target.value)} vlaue={institutionNumber} type="titile" id="titile" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="4567" required=""/>
                {error.find(item => item.field == "institutionNumber").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "institutionNumber").msg }</p>: null}
            </div>

            <div class="mb-6">   
            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                O Level Course
              </label>
              <div className="mt-2">
                <select onChange={(e) => setOLevelCode(e.target.value)}
                  value={oLevelCode}
                  id="oLevelCode"
                  name="oLevelCode"
                  autoComplete="country-name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">--select--</option>  
                  {oLevelSubjects.map((course, i) => 
                     <option value={course.code}>{`${ course?.subject1 && course?.subject2 && course?.subject3  ? course?.subject1 + ', ' + course?.subject2 + ', ' + course?.subject3 : course?.subject1 && course?.subject2 ?  course?.subject1 + ', ' + course?.subject2 : course?.subject1 ? course?.subject1 : null}`}</option>
                  )}
                </select>
                {error.find(item => item.field == "oLevelCode").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "oLevelCode").msg }</p>: null}
              </div>
            </div>
            </div>  
            

            <div class="mb-6">   
            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                A Level Course
              </label>
              <div className="mt-2">
                <select onChange={(e) => setALevelCode(e.target.value)}
                  value={aLevelCode}
                  id="aLevelCode"
                  name="aLevelCode"
                  autoComplete="country-name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">--select--</option>  
                  {aLevelSubjects.map((course, i) => 
                     <option value={course.code}>{`${course?.subject1}, ${course?.subject2}, ${course?.subject3}`}</option>
                  )}
                </select>
                {error.find(item => item.field == "aLevelCode").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "aLevelCode").msg }</p>: null}
              </div>
            </div>
            </div>  

            <div class="mb-6">
                <div className='flex space-x-8 items-center'>
                <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                    Upload your picture
                </label>
                
                    {uploadCapture ? 
                        <div>
                            <img src= {photoURL} className='w-24 h-24' />
                            <button onClick={() => {setUploadCapture(false); setPhotoURL(null); setPhoto(null)}} type="button" class="mt-2 text-white bg-red-500 hover:bg-red-600  focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">remove</button>
                        </div> 
                        : <div class="flex items-center bg-grey-lighter">
                        <label class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                            <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span class="mt-2 text-base leading-normal">Select a file</span>
                            <input type='file' class="hidden" onChange={handlePhotoUpload}/>
                        </label>
                    </div>}
                    {error.find(item => item.field == "photo").msg ? <p class="text-red-500 text-sm font-semibold">{error.find(item => item.field == "photo").msg }</p>: null}
                </div>
                
                {liveCapture ? <WebcamCapture/> : <div className='flex items-center'>
                    <button onClick={() => setLiveCapture(true)} type="button" class="text-white bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2 -ml-1 hover:w-10 hover:h-10">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                        </svg>
                        Capture a Live Picture
                    </button>
                </div>}  
              </div>   

                

            </div> 

            <div class="flex items-center mt-8">
                <div class="flex items-center h-5">
                <input onChange={(e) => {e.target.checked? setStatus(1) : setStatus(0)}} checked={status} id="remember" type="checkbox" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required=""/>
                </div>
                <label for="remember" class="ml-2 text-sm font-medium text-gray-500">By submitting this form, you agree with IJMB policies, rules and regulations</label>        
            </div>
            {error.find(item => item.field == "status").msg ? <p class="text-red-500 text-sm font-semibold mt-2">{error.find(item => item.field == "status").msg }</p>: null}
            <button onClick={() => updateLit(user, lit._id)} type="button" class="text-white bg-green-600 hover:bg-green-500 focus:outline-none font-medium mt-8 rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
               Save         
            </button>                    
        </div>
    </div> 
        

    )
}

export default EditLit