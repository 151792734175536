import { aLevelCourses, localGovernments, nigerianLocalGovernments, nigerianStates, oLevelCourses } from "../data";

export const reg_name = /^[a-zA-Z/s]{2,100}/
export const reg_username = /^[a-zA-Z0-9]{2,100}/
export const reg_title = (/^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$/)
export const reg_desc = /[A-Za-z0-9!@#$%&*()-_+=:;//\/.?{},]{0,200}/;
export const reg_text = (/^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$/)
export const reg_id = /^[A-Za-z0-9]{1,}$/ 
export const reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
export const reg_phoneNumber = /^[0-9]{11}$/
//export const  reg_name = /\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/;
//export const reg_password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/;
export const reg_password = /^[A-Za-z0-9]{6,50}/ 
//export const reg_url = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const reg_url = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
export const reg_type = /^[A-Za-z0-9]{1,50}$/;
export const reg_number = /^[0-9]{1,}$/;
export const reg_address = /^(?=.*[A-Za-z0-9])(?=.*[A-Za-z])[A-Za-z0-9\s\-\.,]+$/;
export const reg_nin = /^[0-9]{11}$/
export const reg_date = /^\b(?:(?:(?:0?[1-9]|1[0-2])[-/.](?:0?[1-9]|[12]\d|3[01])[-/.](?:\d{2})?\d{2})|(?:\d{2}[-/.](?:0?[1-9]|1[0-2])[-/.](?:0?[1-9]|[12]\d|3[01]))|(?:\d{4}[-/.](?:0?[1-9]|1[0-2])[-/.](?:0?[1-9]|[12]\d|3[01])))\b$/
export const reg_state = /^[0-9]{1,2}$/
export const reg_localGovernment = /^[0-9]{1,}$/
export const reg_oLevel = /^[0-9]{1,}$/
export const reg_aLevel = /^[0-9]{1,}$/

// stateList, localGovList


export const validateEmail = (email) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(email != "" && email != undefined){
        if(reg_email.test(email)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid email address"};    
        }
    }else{
        res = {error: "error", result: "email address is required"};
    }
    return res;
};

export const validatePhoneNumber = (phoneNumber) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(phoneNumber != "" && phoneNumber != undefined){
        if(reg_phoneNumber.test(phoneNumber)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "phone number must be 11 digits number"};    
        }
    }else{
        res = {error: "error", result: "phone number is required"};
    }
    return res;
};

export const validatePassword = (password) => {
    //password = req.body.password;
    var res = {error:"error", result:"Unknown"};
    if(password != "" && password != undefined){
        if(reg_password.test(password)){
            res = {error:"", result:""};;
            
        }else{
            res = {error: "error", result: "password must be 6 combination of alphabets and/or numbers"};    
        }
    }else{
        res = {error: "error", result: "password is required"};
    }
    return res;
};

export const validateName = (name) => {
  //name = req.body.name;
  var res = {error:"error", result:"Unknown"};
  if(name != "" && name != undefined){
      if(reg_name.test(name)){
          res = {error:"", result:""};;
          
      }else{
          res = {error: "error", result: "name must contain only alphabets. 2 to 100 in length"};    
      }
  }else{
      res = {error: "error", result: "name is required"};
  }
  return res;
};

export const validateMiddleName = (name) => {
    //name = req.body.name;
    var res = {error:"error", result:"Unknown"};
    if(name != "" && name != undefined){
        if(reg_name.test(name)){
            res = {error:"", result:""};;
            
        }else{
            res = {error: "error", result: "name must contain only alphabets. 2 to 100 in length"};    
        }
    }else{
        res = {error: "", result: ""};
    }
    return res;
  };

export const validateUsername = (username) => {
    //name = req.body.name;
    var res = {error:"error", result:"Unknown"};
    if(username != "" && username != undefined){
        if(reg_username.test(username)){
            res = {error:"", result:""};;
            
        }else{
            res = {error: "error", result: "invalid username"};    
        }
    }else{
        res = {error: "error", result: "username is required"};
    }
    return res;
  };

  export const validateInstitution = (username) => {
    //name = req.body.name;
    var res = {error:"error", result:"Unknown"};
    if(username != "" && username != undefined){
        if(reg_username.test(username)){
            res = {error:"", result:""};;   
        }else{
            res = {error: "error", result: "invalid institution"};    
        }
    }else{
        res = {error: "", result: ""};
    }
    return res;
  };



export const validateTitle = (title) => {
    //name = req.body.name;
    var res = {error:"error", result:"Unknown"};
    if(title != "" && title != undefined){
        if(reg_title.test(title)){
            res = {error:"", result:""};;
            
        }else{
            res = {error: "error", result: "invalid title"};    
        }
    }else{
        res = {error: "error", result: "title is required"};
    }
    return res;
};

export const validateText = (text) => {
    //name = req.body.name;
    var res = {error:"error", result:"Unknown"};
    if(text != "" && text != undefined){
        if(reg_text.test(text)){
            res = {error:"", result:""};;
            
        }else{
            res = {error: "error", result: "invalid text"};    
        }
    }else{
        res = {error: "error", result: "text is required"};
    }
    return res;
};

export const validateDescription = (desc) => {
  var res = {error:"error", result:"Unknown"};
  //desc = req.body.desc;
  if(desc != "" && desc != undefined){
      if(reg_desc.test(desc)){
        res = {error:"", result:""};;
        
    }else{
          res = {error: "error", result: "invalid description. 200 min"};    
      }
  }else{
      res = {error: "", result: ""};
  }
  return res;
};

export const validateLink = (link) => {
  //link = req.body.link;
  var res = {error:"error", result:"Unknown"};
  if(link != "" && link != undefined){
      if(reg_url.test(link)){
          res = {error:"", result:""};;
          
      }else{
          res = {error: "error", result: "invalid link"};    
      }
  }else{
      res = {error: "error", result: "link is required"};
  }
  return res;
};

export const validateImage = (image) => {
    //link = req.body.link;
    var res = {error:"error", result:"Unknown"};
    if(image != "" && image != undefined){
        /*if(reg_url.test(image)){
            res = {error:"", result:""};
            
        }else{
            res = {error: "error", result: "invalid image"};    
        }*/
        res = {error:"", result:""};
    }else{
        res = {error: "error", result: "image is required"};
    }
    return res;
};

export const validateID_post = (id) => {
  //id = req.body.id;  
  var res = {error:"error", result:"Unknown"};
  if(id != "" && id != undefined){
      if(reg_id.test(id)){
          res = {error:"", result:""};;
          
      }else{
          res = {error: "error", result: "invalid ID"};    
      }
  }else{
      res = {error: "error", result: "ID is required"};
  }
  return res;
};

export const validateID_get = (id) => {
    //id = req.query.id;  
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        if(reg_id.test(id)){
            res = {error:"", result:""};;
            
        }else{
            res = {error: "error", result: "invalid ID"};    
        }
    }else{
        res = {error: "error", result: "ID is required"};
    }
    return res;
  };

export const validatePaymentType = (type) => {
  //type = req.body.type;
  var res = {error:"error", result:"Unknown"};
  if(type != "" && type != undefined){
      if(reg_type.test(type)){
          res = {error:"", result:""};;
          
      }else{
          res = {error: "error", result: "invalid type"};    
      }
  }else{
      res = {error: "error", result: "type is required"};
  }
  return res;
};

export const validateTransactionID = (id) => {
    //id = req.query.transaction_id;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:""};;
        
    }else{
        res = {error: "error", result: "transaction id is required"};
    }
    return res;
};
  
export const validateToken = (id) => {
    //id = req.query.token;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:""};;
        
    }else{
        res = {error: "error", result: "token is required"};
    }
    return res;
};

export const validateParentID = (id) => {
    //id = req.body.parent_id;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:""};;
        
    }else{
        res = {error: "error", result: "parent id is required"};
    }
    return res;
};

export const validateParentName = (parent_name) => {
    //var parent_name = req.body.parent_name; 
    var res = {error:"error", result:"unknown"};
    if(parent_name != "" && parent_name != undefined){
        if(reg_name.test(parent_name)){
            res = {error: "", result: ""};
              //next();  
        }else{
            res = {error: "error", result: "invalid parent name"}    
        }
    }else{
        res = {error: "error", result: "parent name is required"}
    }
    return res;
}

export const validateCollectionID_post = (id) => {
    //id = req.body.col_id;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:"This id is valid"};;
        
    }else{
        res = {error: "error", result: "collection id is required"};
    }
    return res;
};

export const validateCollectionID_get = (id) => {
    //id = req.body.col_id;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:""};;
        

    }else{
        res = {error: "error", result: "collection id is required"};
    }
    return res;
};
//alert(validateEmail("hjsjsj@gmail.com").result);
//alert(validatePassword("hsjhsjs").result);

export const validateResourceID_post = (id) => {
    //id = req.body.res_id;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:"This id is valid"};;
        

    }else{
        res = {error: "error", result: "resource id is required"};
    }
    return res;
};

export const validateResourceID_get = (id) => {
    //id = req.query.res_id;
    var res = {error:"error", result:"Unknown"};
    if(id != "" && id != undefined){
        res = {error:"", result:"This id is valid"};;
        

    }else{
        res = {error: "error", result: "resource id is required"};
    }
    return res;
};

export const validateNumber = (num) => {
    //id = req.query.id;  
    var res = {error:"error", result:"Unknown"};
    if(num != "" && num != undefined){
        if(reg_number.test(num)){
            res = {error:"", result:""};
            
        }else{
            res = {error: "error", result: "invalid number"};    
        }
    }else{
        res = {error: "error", result: "Field is required"};
    }
    return res;
  };

  export const validateGender = (gender) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(gender != "" && gender != undefined){
        if(gender == 1 || gender == 2 ){
            res = {error: "", result: ""};
        }else{
            res = {error: "error", result: "invalid gender"};    
        }
    }else{
        res = {error: "error", result: "gender is required"};
    }
    return res;
};
export const validateDate = (date) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(date != "" && date != undefined){
        if(reg_date.test(date)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid date"};    
        }
    }else{
        res = {error: "error", result: "date is required"};
    }
    return res;
};
export const validateNIN = (nin) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(nin != "" && nin != undefined){
        if(reg_nin.test(nin)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid nin number"};    
        }
    }else{
        res = {error: "error", result: "nin number is required"};
    }
    return res;
};

export const validateState = (code) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(code != "" && code != undefined){
        if(reg_state.test(code)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid state"};    
        }
    }else{
        res = {error: "error", result: "this field is required"};
    }
    return res;
};

export const validateLocalGovernment = (code) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(code != "" && code != undefined){
        if(reg_localGovernment.test(code)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid local government"};    
        }
    }else{
        res = {error: "error", result: "this field is required"};
    }
    return res;
};



export const validateOLevelCode = (code) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(code != "" && code != undefined){
        if(reg_oLevel.test(code)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid o level code"};    
        }
    }else{
        res = {error: "", result: ""};
    }
    return res;
};

export const validateALevelCode = (code) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(code != "" && code != undefined){
        if(reg_aLevel.test(code)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid a level code"};    
        }
    }else{
        res = {error: "error", result: ""};
    }
    return res;
};

export const validateCombination = (oLevelCode, aLevelCode) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(oLevelCode || aLevelCode){
        //alert("Fields are here")
        //alert(JSON.stringify(oLevelCode))
        //alert(JSON.stringify(aLevelCode))
        res = {error: "", result: ""};   
    }else{
        //alert("Fields absent")
        res = {error: "error", result: "you must select an A/Level combination or O/Level or both"};
    }
    return res;
};


/*export const validateALevelCode = (code) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(code != "" && code != undefined){
        if(aLevelCourses.findIndex( (course) => course.CourseCode == code)){
            res = {error: "", result: ""};
        }else{
            res = {error: "error", result: "invalid A Level code"};    
        }
    }else{
        res = {error: "error", result: "A Level course required"};
    }
    return res;
};*/

export const validateAddress = (address) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(address != "" && address != undefined){
        if(reg_address.test(address)){
            res = {error: "", result: ""};
                
        }else{
            res = {error: "error", result: "invalid address"};    
        }
    }else{
        res = {error: "error", result: "this field is required"};
    }
    return res;
};

export const validateStatus = (status) => {
    //email = req.body.email; 
    var res = {error:"error", result:"Unknown"};
    if(status){
        if(status == 1){
            res = {error: "", result: ""};
        }else{
            res = {error: "error", result: "Agreement form must be checked"};    
        }
    }else{
        res = {error: "error", result: "Agreement form is required"};
    }
    return res;
};

/*export const validateFundingCategory = (cat) => {
    //id = req.query.res_id;
    var res = {error:"error", result:"Unknown"};
    if(cat != "" && cat != undefined){
        if(Category.includes(cat)){
            res = {error:"", result:""};
        }else{
            res = {error:"error", result:"Invalid input"};
        }
        
        

    }else{
        res = {error: "error", result: "Field is required"};
    }
    return res;
};*/




