import axios from "axios";
//axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';


export const postData = async (url, headers, data) => {
  try{
        //alert(JSON.stringify(headers))
         const res = await axios.post(url, data, {headers: headers });
          //alert(JSON.stringify(res))
          //alert('response')
          return {error: "", result: res};
            
      }catch(error){
        //alert(JSON.stringify(error.response))
        //alert('error')
        if(error.response){
          return {error: "error", result: error.response.data}
        }else if(error.request){
          return {error: "errror", result: error.request}
        }else{
          return {error: "error", result: error.message}
        }
      }
}

export const updateData = async (url, headers, data) => {
  try{
        //alert(JSON.stringify(headers))
         const res = await axios.put(url, data, {headers: headers });
          alert(JSON.stringify(res))
          //alert('response')
          return {error: "", result: res};
            
      }catch(error){
        alert(JSON.stringify(error.response))
        //alert('error')
        return {error: "error", result: error}
      }
}

export const getData = async (url, headers, params) => {
  try{
          
      const res = await axios.get(url, {params:params, headers: headers});
      //alert(JSON.stringify(res))
      return {error: "", result: res};
          
    }catch(error){
      //alert(JSON.stringify(error.response))
      return {error: "error", result: error}
    }
}

export const deleteData = async (url, headers, params) => {
  try{
          
      const res = await axios.delete(url, {params:params, headers: headers});
      //alert(JSON.stringify(res))
      return {error: "", result: res};
          
    }catch(error){
      // alert(JSON.stringify(error.response))
      return {error: "error", result: error}
    }
}